import { createStore, Reducer, Action } from "redux"
import { devToolsEnhancer } from "redux-devtools-extension"

export interface StoreState {
  isSidebarVisible: boolean
}

// Actions
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR"
export type TOGGLE_SIDEBAR = typeof TOGGLE_SIDEBAR
export interface ToggleSidebar extends Action {
  type: TOGGLE_SIDEBAR
}
export const toggleSidebar = () => ({ type: TOGGLE_SIDEBAR })

export const initialState: StoreState = { isSidebarVisible: false }

// Reducer
export const reducer: Reducer<StoreState> = (
  state: StoreState = initialState,
  action: ToggleSidebar
): StoreState => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return Object.assign({}, state, {
        isSidebarVisible: !state.isSidebarVisible,
      })
    default:
      return state
  }
}

// Store

export const store = createStore<StoreState, any, any, any>(
  reducer,
  initialState,
  devToolsEnhancer({})
)
