import * as React from "react"
import { connect, MapStateToProps } from "react-redux"
import { Dispatch } from "redux"
import { GatsbyLinkProps } from "gatsby-link"
import { StoreState } from "../../store"
import { MenuProps, MenuItem } from "../Menu"
import { Menu, Icon, Sidebar } from "semantic-ui-react"
import { SemanticICONS } from "semantic-ui-react"

interface SidebarMenuProps extends MenuProps {
  dispatch?: Dispatch<any>
  Link: React.ComponentClass<GatsbyLinkProps<any>>
  visible: boolean
}

interface StateProps {
  visible: boolean
}

type Props = StateProps & SidebarMenuProps

export const SidebarMenu = (props: Props) => {
  const { items, pathname, Link, visible } = props
  const isActive = (item: MenuItem) =>
    item.exact ? pathname === item.path : pathname.startsWith(item.path)
  const activeItem =
    items.find((item: MenuItem) => isActive(item)) || ({} as MenuItem)
  return (
    <Sidebar
      as={Menu}
      animation="slide along"
      width="thin"
      visible={visible}
      icon="labeled"
      vertical
      inverted={activeItem.inverted}
    >
      {items.map(item => {
        const active = isActive(item)
        return (
          <Menu.Item as={Link} to={item.path} active={active} key={item.path}>
            <Icon name={item.icon as SemanticICONS} />
            {item.name}
          </Menu.Item>
        )
      })}
    </Sidebar>
  )
}

declare module "react-redux" {
  interface DefaultRootState {
    isSidebarVisible: false
  }
}

const mapStateToProps: MapStateToProps<
  StateProps,
  SidebarMenuProps,
  StoreState
> = (state: StoreState): StateProps => ({
  visible: state.isSidebarVisible,
})

// Typical usage: `connect` is called after the component is defined
export default connect<StateProps, {}, SidebarMenuProps>(mapStateToProps)(
  SidebarMenu
)
